import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We all know how important is to work on side projects for your skills, professional and personal life.`}</p>
    <p>{`People want to start these side projects but often they get stuck thinking too big. They want ideas that are original, big or never been tackled before by other companies.`}</p>
    <p>{`That’s not always the case while working on side projects.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2000/0*AjCoxiToTbgE8JOs",
        "alt": "Image"
      }}></img></p>
    <p>{`You don’t always need an original idea.`}</p>
    <p>{`You are not starting a company.`}</p>
    <p>{`You are just building skills.`}</p>
    <p>{`But, how can you find what to work on your next project?`}</p>
    <p>{`These some 5 ways to find ideas for your project:`}</p>
    <h2>{`Scratch your own itch`}</h2>
    <p>{`Have you been thinking to the same idea for weeks or years? Did you find an app that you feel it could been done better? Did you stumble upon something and you ask yourself why that service does not exist yet?
Scratching your own itch is one of the best way to find side projects that you will enjoy working on.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/9626/0*tgd4lLts2pn-_Rbh",
        "alt": "Image"
      }}></img></p>
    <h2>{`Talk to people`}</h2>
    <p>{`Ask the same questions above to people around you. Friends, family, co-workers, classmates. They will have different problems in their lives and you will get some many interesting ideas you could work on while impacting on other people’s lives.`}</p>
    <p>{`I would suggest you to read `}<a parentName="p" {...{
        "href": "http://paulgraham.com/startupideas.html"
      }}>{`How to get Startup ideas`}</a>{` by Paul Graham.`}</p>
    <h2>{`Evaluate your day`}</h2>
    <p>{`Write a diary of all the things that you do during your day. What could be done better?`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/1000/0*21IkzFO6pKJz1QMb",
        "alt": "Image"
      }}></img></p>
    <p>{`What are you struggling on?`}</p>
    <p>{`What would you like to add to your day but you always forget?`}</p>
    <p>{`Answering these questions could give you some good ideas.`}</p>
    <h2>{`Browse sites`}</h2>
    <p>{`Browse sites like `}<a parentName="p" {...{
        "href": "https://news.ycombinator.com/show"
      }}>{`SHOW HN`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.producthunt.com/"
      }}>{`ProductHunt`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/trending"
      }}>{`Github`}</a>{`.
You will find interesting product that you could do better or product that are missing some cool features.`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      